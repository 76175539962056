import { selectors } from './utils/constants';

class PromoBanners {
  visibleListingUidsToCheck() {
    let uidsList = [];
    document.querySelector(selectors.LISTINGS_WRAPPER)
      .querySelectorAll('.js-within-viewport .js-featured-label-wrapper.js-recheck-required:not(.js-recheck-in-progress)')
      .forEach( el => {
        let uid = el.closest(selectors.LISTING).id;
        uidsList.push(uid);
      });
    return uidsList;
  }

  recheckAjaxRequest(uid) {
    const l = document.getElementById(uid);
    let wrap = l.querySelector('.js-featured-label-wrapper');
    if (!wrap || wrap.classList.contains('js-recheck-in-progress')) { return; }
    wrap.classList.add('js-recheck-in-progress');
    fetch(wrap.getAttribute('data-recheck-url'), {
      method: 'POST',
      headers: {'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')}
    }).then((resp) => {
      return resp.json();
    }).then((result) => {
      l.querySelector('.js-promo-wrapper').innerHTML = result.html;
      wrap = l.querySelector('.js-featured-label-wrapper');
      wrap.style.display = result.promo_banner ? 'block' : 'none';
      if (result.promo_banner) {
        l.classList.add('featured');
      }
      wrap.classList.remove('js-recheck-required');
      wrap.classList.remove('js-recheck-in-progress');
      this.triggerPromoBannerCheckingQueue();
    }).catch((e) => {
      console.log(e);
    });
  }

  triggerPromoBannerCheckingQueue() {
    if (document.querySelector(selectors.LISTINGS_WRAPPER)
      .querySelectorAll('.js-featured-label-wrapper.js-recheck-in-progress').length) { return; }
    let uid = this.visibleListingUidsToCheck()[0];
    if (uid) { this.recheckAjaxRequest(uid); }
  }

  isInViewport(element) {
    const rect = element.getBoundingClientRect();
    return (
      rect.top >= 0 &&
      rect.left >= 0 &&
      rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) + 200 &&
      rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
  }

  refreshVisiblePromoBanners() {
    const listings = document.querySelectorAll(`${selectors.LISTINGS_WRAPPER} ${selectors.LISTING}`);
    listings.forEach( l => {
      l.classList.remove('js-within-viewport');
    });

    listings.forEach(l => {
      if (this.isInViewport(l)) {
        l.classList.add('js-within-viewport');
      }
    });

    setTimeout(() => this.triggerPromoBannerCheckingQueue(), 300);
  }

  bindComingSoonBannerRefresh() {
    $(window).on('resize scroll', () => this.refreshVisiblePromoBanners());
  }

  bindEvents() {
    this.bindComingSoonBannerRefresh();
  }

  init() {
    this.bindEvents();
  }
}

export default PromoBanners;